import {Link, Router, Routes, Route} from 'react-router-dom'
import Ssvg from '../components/Ssvgg';
import Hsvg from '../components/Hsvg';
import Name from '../components/Name.js';
import Me from '../me.png';
import Hand from '../hand.png';
import PageLink from '../components/PageLink.js';
import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { collection } from 'firebase/firestore';
import db from '../components/Firebase/firebase.js'
import {getStorage, ref, getDownloadURL} from "firebase/storage";
import Git from '../github.png';


import '../stylesheets/Projects.css'


function Projects() {

const [projects, setProjects] = useState([]);

const storage = getStorage();
const storageRef = ref(storage);

const imagesRef = ref(storage, 'images');
const gandRef = ref(storage, 'images/gandy.png');

// console.log(gandRef)






useEffect(() =>{
    onSnapshot(collection(db, "projects"),(snapshot) => {
    //   console.log(snapshot.docs.map(doc => doc.data()))
      var arr = [];
      snapshot.docs.map((doc) => {
        arr.push(doc.data())
      })

      setProjects(arr);
    });
  },[])

  useEffect(() =>{
    
    
  },[projects])

  function renderImages(project){
    project.images.map((i) =>{
        getDownloadURL(ref(storage, 'images/' + i + '.png'))
    .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'

     // This can be downloaded directly:
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      const blob = xhr.response;
    };
    xhr.open('GET', url);
    xhr.send();

    // Or inserted into an <img> element
    const img = document.getElementById(i);
    img.setAttribute('src', url);
    })
    .catch((error) => {
        //Handle errors
        console.log(error)
    })
    })
  }

  const renderCollaborators = (p) =>{
    const numCollabs = Object.entries(p.collaborators).length;
    // console.log(objectLength);
    // var len = p.collaborators["c0"]
    // console.log(len)

    return p.collaborators.map((element) =>{
      return(
        <li><a href={element["site"]}>{element["name"]}</a></li>
      )
    })
    
  }

  const clickImage = (e) =>{
    // Get the gallery box
    var imageBox1 = document.getElementById("imageBox1");

    // Get the modal image tag
    var modal = document.getElementById("myModal");

    var modalImage = document.getElementById("modal-image");


    var src = e.target.src;
    modal.style.display = "block";
    modalImage.src = src;
  }

  const closeModal = () => {
    // Get the modal image tag
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
  }

  window.onclick = function (event) {
    var modal = document.getElementById("myModal");
    if (event.target == modal) {
      modal.style.display = "none";
    }
  };

  

  return (
      <div className='projectsPageBox'>
        <h2 className='projectsHeader'>portfolio</h2>
        <h3>shay is hard at work on this page! please come back soon. (7/16/2024)</h3>
        {/* <div className='projectsBox'>

          <div id="myModal" class="modal">
            <div class="modal-content">
              <span onClick={closeModal} class="close">&times;</span>
              <img src="" id="modal-image" />
            </div>
          </div>

            {
                projects.map((p) =>{
                    return(
                        <div className='project'>
                            <p>{p.name}</p>
                            <div className='descAndImages'>
                              <p className='projectDesc'>{p.description}</p>
                              <div className='projectImages'>
                               {
                                   p.images.map((name) =>{
                                       return(<img className='projectImage' onClick={clickImage} id={name} />)
                                   })
                                   
                               }
                              </div>
                              <a href={p.references[0]}><img src={Git} /></a>
                            </div>
                            {renderImages(p)}
                            <div className='collaboratorsBox'>
                                {
                                  p.collaborators.length != 0 ?
                                  <ul>
                                    Collaborators:
                                    {renderCollaborators(p)}
                                  </ul>
                                  :
                                  <div></div>
                                }
                            </div>
                        </div>
                    )              
                })
            }
          
        </div> */}
        
        
      
      </div>
      
  );
}

export default Projects;
