import {Link, Router, Routes, Route} from 'react-router-dom'
import Ssvg from '../components/Ssvgg';
import Hsvg from '../components/Hsvg';
import Name from '../components/Name.js';
import Me from '../me.png';
import Shay from '../shay.jpeg';
import Hand from '../hand.png';
import PageLink from '../components/PageLink.js';


import '../stylesheets/Landing.css'


function Landing() {
  return (
      <div className='landingPage'>
        <div className='topBox'>
          <Hsvg />
          <h3>software engineer / ohio</h3>
        </div> 


        <div className='bottomBox'>
          {/* <div>IMAGE OF ME</div> */}
          
          <div className='linkBox'>
            {/* <PageLink to='about' text='About Me' className='firstLink'/>
            <PageLink to='projects' text='Portfolio' />
            <PageLink to='blog' text='Blog'/> */}
          </div> 
        </div>
      </div>
      
  );
}

export default Landing;
