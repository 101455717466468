import { useParams } from "react-router-dom";
import '../stylesheets/BlogPost.css'
import db from "../components/Firebase/firebase";
import {onSnapshot} from "@firebase/firestore";
import {collection} from "@firebase/firestore";
import { useState, useEffect } from "react";
import ReactHtmlParser from 'react-html-parser';


function BlogPost(){
    const pageData = useParams();
    const [fileData, setFileData] = useState({});

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        onSnapshot(collection(db, "blogPosts"), (snapshot) => {
            snapshot.docs.map(doc => {
                const data = doc.data();

                if(data.id === pageData.id){
                    setFileData(data);
                }
            });
        });
    }
        
    return(
        <div className="contentContainer">
            <h2>{fileData.header}</h2>
            <h3>{fileData.subheader}</h3>
            <div class="para">{ ReactHtmlParser(fileData.textFile) }</div>
        </div>
    )
}

export default BlogPost;