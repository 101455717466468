import React, { useState, createRef, useEffect } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import Ssvg from './Ssvgg';
import Hsvg from './Hsvg';
import Asvg from './Asvg';
import Ysvg from './Ysvg';

import '../stylesheets/Name.css'

const Name = (props) => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  });
  const pathRef = createRef();
  const [pathLength, setPathLength] = useState();

  useEffect(() => {
    if (pathRef.current) {
      setPathLength(pathRef.current.getTotalLength());
    }
  }, [pathRef]);

  return (
    <div className='cont'>
        <Ssvg />
        <Hsvg />
        <Asvg />
        <Ysvg />
    </div>
  );
};

const Wrapper = styled.div`
  .animated {
    max-width: 100vw;
    width: auto;
    height: auto;
    stroke-dasharray: ${(props) => props.pathLength};
    stroke-dashoffset: ${(props) => props.pathLength};
  }
  .animated.visible {
    animation: draw 6s linear forwards;
  }
  @keyframes draw {
    from {
      stroke-dashoffset: ${(props) => props.pathLength};
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`;

export default Name;