import React, { useState } from 'react';
import '../stylesheets/Carousel.css';
import {BsArrowLeftCircleFill, BsArrowRightCircleFill} from 'react-icons/bs';

import MeDress from "./carousel/shay.jpeg"
import MeSmile from "./carousel/MeSmile.jpg"
import MeMirror from "./carousel/MeMirror.JPG"
import MeGame from "./carousel/MeGame.JPG"
import MeClass from "./carousel/MeClass.jpg"

export const Carousel = (data) => {
    const [slide, setSlide] = useState(0);

    const nextSlide = () => {
        if(slide == (5-1)){
            setSlide(0);
        }else{
            setSlide(slide+1);
        }
    }

    const prevSlide = () => {
        if(slide == 0){
            setSlide(5 - 1);
        }else{
            setSlide(slide - 1);
        }
    } 

    console.log(data.data);
    return (
        <div className='carousel'>
            <BsArrowLeftCircleFill className='arrow arrow-left' onClick={prevSlide}/>
            {
                // data.data.map((item, idx) => {
                //     return (
                //     <img className={slide === idx ? "slide" : "slide slide-hidden"} src={item.src} alt={item.alt} />
                //     );
                // })
            }
            <img className={slide === 0 ? "slide" : "slide slide-hidden"} src={MeClass} alt="dog" />
            <img className={slide === 1 ? "slide" : "slide slide-hidden"} src={MeSmile} alt="dog" />
            <img className={slide === 2 ? "slide" : "slide slide-hidden"} src={MeMirror} alt="dog" />
            <img className={slide === 3 ? "slide" : "slide slide-hidden"} src={MeGame} alt="dog" />
            <img className={slide === 4 ? "slide" : "slide slide-hidden"} src={MeDress} alt="dog" />

            <BsArrowRightCircleFill className='arrow arrow-right' onClick={nextSlide}/>
            {/* <span className='indicators'>
                {/* {data.data.map((_, idx1) => {
                        return (<button key={idx1+"_"} onClick={() => setSlide(idx1)} className={slide === idx1 ? "indicator" : "indicator indicator-inactive"}></button>);
                })} */}
                {/* <button key={0+"_"} onClick={() => setSlide(0)} className={slide === 0 ? "indicator" : "indicator indicator-inactive"}></button>
                <button key={1+"_"} onClick={() => setSlide(1)} className={slide === 1 ? "indicator" : "indicator indicator-inactive"}></button>
                <button key={2+"_"} onClick={() => setSlide(2)} className={slide === 2 ? "indicator" : "indicator indicator-inactive"}></button>
                <button key={3+"_"} onClick={() => setSlide(3)} className={slide === 3 ? "indicator" : "indicator indicator-inactive"}></button>
                <button key={4+"_"} onClick={() => setSlide(4)} className={slide === 4 ? "indicator" : "indicator indicator-inactive"}></button> */}
            {/* </span> */}
            
        </div>
    )
}