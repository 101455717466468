import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Landing from './pages/Landing';
import './stylesheets/App.css'
import Header from './components/Header';
import Footer from './components/Footer';
import About from './pages/About'
import Projects from './pages/Projects';
import Blog from './pages/Blog';
import db from '../src/components/Firebase/firebase'
import { useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import BlogPost from './pages/BlogPost';

function App() {
  

  return (
    <Router>
      <div className='App'>
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
        <link href="https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"></link>
        <Header className='mainHeader'/>
        <div className='currentPage'>
          
            <Routes>
              <Route path="/" Component={Landing}></Route>
              <Route path="/about" Component={About}></Route>
              <Route path="/portfolio" Component={Projects}></Route>
              <Route path="/blog" Component={Blog}></Route>
              <Route path="/blog/:id" Component={BlogPost}></Route>
            </Routes>
          
        </div>
        {/* <Footer className='mainFooter' /> */}
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
