import {Link, Router, Routes, Route} from 'react-router-dom'
import Ssvg from '../components/Ssvgg';
import Hsvg from '../components/Hsvg';
import Name from '../components/Name.js';
import Me from '../me.png';
import Hand from '../hand.png';
import PageLink from '../components/PageLink.js';
import { Carousel } from '../components/Carousel.js';
import DATA from '../components/carousel/carouselData.json';
import '../stylesheets/About.css'
import { useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { collection } from 'firebase/firestore';
import db from '../components/Firebase/firebase.js'
import {getStorage, ref, getDownloadURL} from "firebase/storage";

function About() {

  const [about, setAbout] = useState([]);
  const storage = getStorage();
const storageRef = ref(storage);

const imagesRef = ref(storage, 'images');

  useEffect(() =>{
    console.log(db)

    const poop = onSnapshot(collection(db, "about"),(snapshot) => {
    //   console.log(snapshot.docs.map(doc => doc.data()))
      console.log("poop");
      var arr = [];
      snapshot.docs.map((doc) => {
        arr.push(doc.data())
      })

      setAbout(arr);
    });
  },[])

  console.log(about)


  function renderImages(project){
    project.images.map((i) =>{
        getDownloadURL(ref(storage, 'images/' + i + '.png'))
    .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'

     // This can be downloaded directly:
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      const blob = xhr.response;
    };
    xhr.open('GET', url);
    xhr.send();

    // Or inserted into an <img> element
    const img = document.getElementById(i);
    img.setAttribute('src', url);
    })
    .catch((error) => {
        //Handle errors
        console.log(error)
    })
    })
  }

  const renderCollaborators = (p) =>{
    const numCollabs = Object.entries(p.collaborators).length;
    // console.log(objectLength);
    // var len = p.collaborators["c0"]
    // console.log(len)

    return p.collaborators.map((element) =>{
      return(
        <li><a href={element["site"]}>{element["name"]}</a></li>
      )
    })
    
  }

  return (
      <div className='aboutPageBox'>
        <Carousel className="myPics" data={DATA.slides} />
        <h2 className='aboutHeader'>about me</h2>
        <div className='paragraph'>
        {/* <img className='image-me' src={Me} /> */}
          <p className='paragraph-me'>
            Thanks for stopping by! I'm Shay, a software developer from Warren, Ohio, USA. This is a VERY early build of this site, and it will be updated regularly for the foreseeable future. I'm currently working full-time, but I am always open to freelance opportunities. Please come back when I have webmail implemented. Please visit my "portfolio" page to see some examples of my technical skills.
          </p>
          <p>This website was created from scratch using <b>React</b>, and all dynamic conent is stored with <b>Firebase</b>.</p>
          
        </div>

      
      </div>
      
  );
}

export default About;
